import 'core-js/stable';
import 'fast-text-encoding'; // Polyfill for TextEncoding, required for Edge browser
import 'underscore';
import 'intersection-observer';
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime';
import MyApp from './app';

// Clock start of loading process
window.startTime = new Date().getTime();

// @ts-ignore
window.URL = window.URL || window.webkitURL;

function getParameterByName(
	name: string,
	url?: string,
) {
	if (!url) url = window.location.href;
	name = name.replace(
		/[[\]]/g,
		'\\$&',
	);
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(
		/\+/g,
		' ',
	));
}

if (!(window.history && window.history.pushState)) {
	// IE9 (and other browsers without history support) deeplink workaround
	const rootPath = '/app';
	const location = `${window.location.protocol}//${window.location.hostname}${rootPath}`;
	const hash = window.location.hash.substr(1);

	let fragment = window.location.pathname.substr(rootPath.length + 1);
	if (fragment.length > 0 && hash.length > 0) {
		fragment += `&${hash}`;
	}
	if (fragment.length > 0) {
		window.location.href = `${location}#${fragment}`;
	}
}

// Set globals
const aid = getParameterByName('aid') || getParameterByName('_aid');
window.affiliateID = aid ? parseInt(
	aid,
	10,
) : 0;
window.glAppUrl = BASE_URL ? `${BASE_URL}/` : `${window.location.protocol}//${window.location.host}/`;
window.glPlatform = window.webToNative ? 'native' : 'web';

if (window.glPlatform == 'native') {
	window.nativeToWeb = {};
}

if (
	window.location.host.indexOf('localhost') >= 0
	|| window.location.host.indexOf('192.168.1.') >= 0
	|| window.location.host.indexOf('192.168.0.') >= 0
) {
	window.glStack = 'local';
} else if (window.location.host.indexOf('staging') >= 0) {
	window.glStack = 'staging';
} else if (window.location.host.indexOf('test') >= 1) {
	window.glStack = 'test';
} else {
	window.glStack = 'live';
}

OfflinePluginRuntime.install({
	onInstalled: () => {
		console.log('ServiceWorker: installed');
	},
	onUpdateReady: () => {
		console.log('ServiceWorker: update ready');
		OfflinePluginRuntime.applyUpdate();
	},
	onUpdated: () => {
		console.log('ServiceWorker: updated');
		window.swUpdate = true;
	},
	onUpdating: () => {
		console.log('ServiceWorker: downloading update');
	},
	onUpdateFailed: () => {
		/* Catch the failed update */
		console.log('ServiceWorker: update failed');
	},
});

// @ts-ignore applicationCache does exist in webView in the native iOS app
if (window.glPlatform == 'native' && window.applicationCache) {
	const onAppCacheUpdate = () => {
		console.log('AppCache: updated');
		window.swUpdate = true;
	};

	// @ts-ignore applicationCache does exist in webView in the native iOS app
	if (window.applicationCache.status == window.applicationCache.UPDATEREADY) {
		onAppCacheUpdate();
	}

	// @ts-ignore applicationCache does exist in webView in the native iOS app
	window.applicationCache.addEventListener(
		'updateready',
		onAppCacheUpdate,
	);

	// @ts-ignore applicationCache does exist in webView in the native iOS app
	window.applicationCache.addEventListener(
		'downloading',
		() => { console.log('AppCache: downloading update'); },
	);

	// @ts-ignore applicationCache does exist in webView in the native iOS app
	window.applicationCache.addEventListener(
		'error',
		() => { console.log('AppCache: error during update'); },
	);

	window.setInterval(
		() => {
		// Check for an application update every hour
		// @ts-ignore applicationCache does exist in webView in the native iOS app
			window.applicationCache.update();
		},
		60 * 60 * 1000,
	);
}

// Start app
window.App = new MyApp();
window.App.start();
