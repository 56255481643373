import { callSafe } from '../utils/functions';

export type IsMobileWatchCallback = (isMobile: boolean) => void;

export type IsMobileType = 'normal' | 'strict';

// eslint-disable-next-line import/no-mutable-exports
let isMobile = false;
// eslint-disable-next-line import/no-mutable-exports
let isMobileStrict = false;
const mobileMediaQueryList = window.matchMedia('only screen and (max-width: 767px)');
const mobileMediaQueryListLandscape = window.matchMedia('only screen and (pointer: coarse) and (max-height: 767px) and (orientation: landscape)');
const mobileMediaQueryListPortrait = window.matchMedia('only screen and (pointer: coarse) and (max-width: 767px) and (orientation: portrait)');
const watchers: Record<IsMobileType, IsMobileWatchCallback[]> = {
	normal: [],
	strict: [],
};

function onMobileMediaQueryListChange(type: IsMobileType): void {
	if (type === 'normal') {
		const currentIsMobile = mobileMediaQueryList.matches;
		isMobile = currentIsMobile;

		// eslint-disable-next-line no-restricted-syntax
		for (const watcher of watchers.normal) {
			callSafe(() => watcher(currentIsMobile));
		}
	} else {
		const currentIsMobileStrict = (
			mobileMediaQueryListLandscape.matches
			|| mobileMediaQueryListPortrait.matches
		);
		isMobileStrict = currentIsMobileStrict;

		// eslint-disable-next-line no-restricted-syntax
		for (const watcher of watchers.strict) {
			callSafe(() => watcher(currentIsMobileStrict));
		}
	}
}

function watch(
	callback: IsMobileWatchCallback,
	type: IsMobileType = 'normal',
): () => void {
	watchers[type].push(callback);

	return () => {
		const index = watchers[type].indexOf(callback);

		if (index > -1) {
			watchers[type].splice(
				index,
				1,
			);
		}
	};
}

mobileMediaQueryList.addEventListener(
	'change',
	() => onMobileMediaQueryListChange('normal'),
);
mobileMediaQueryListLandscape.addEventListener(
	'change',
	() => onMobileMediaQueryListChange('strict'),
);
mobileMediaQueryListPortrait.addEventListener(
	'change',
	() => onMobileMediaQueryListChange('strict'),
);

if (
	mobileMediaQueryListLandscape.matches
	|| mobileMediaQueryListPortrait.matches
) {
	isMobileStrict = true;
}
if (mobileMediaQueryList.matches) {
	isMobile = true;
}

export {
	isMobile,
	isMobileStrict,
	watch,
};
