import './defines';
import _ from 'underscore';
import VueObserveVisibility from 'vue-observe-visibility';
import { Component, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';
// import Vuex from 'vuex';
// @ts-ignore: No declaration file
import VueTouch from 'vue-touch';
import { VueHammer } from 'vue2-hammer';
// @ts-ignore: No declaration file
import VueVisible from 'vue-visible';
import ProductState from 'classes/productstate';
import DialogView from 'components/dialogs';
import EventBus from 'components/event-bus';
import IconComponent from 'components/icon';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import navigate from 'controllers/navigate';
import { RouteOptions } from 'interfaces/app';
import * as DialogService from 'services/dialog';
import {
	AppStateModule,
	ConfigModule,
	ProductsModule,
	ProductStateModule,
	UserModule,
} from 'store';
import URL_PARAMETERS from 'settings/url-parameters';
import { Public } from 'utils/decorators';
import SupportView from 'views/support';
import WebPushSoftAskView from 'views/web-push-soft-ask/template.vue';
import deleteProduct from 'mutations/product/delete';
// import logBreadcrumb from 'services/log-breadcrumb';
// eslint-disable-next-line import/no-named-as-default
import ScrollBehavior from 'tools/scroll-behavior';
import Template from './template.vue';

/**
 * Ignore the divider element, as it is not a valid HTML element
 * and should be ignored by Vue as if it were a component.
 */
Vue.config.ignoredElements = [
	'divider',
	'color-palette-item',
];

Vue.component(
	'IconComponent',
	IconComponent,
);
// Vue.use(Vuex);
Vue.use(VueObserveVisibility);
Vue.use(VueRouter);
Vue.use(
	VueTouch,
	{ name: 'VTouch' },
);
Vue.use(VueHammer);
Vue.use(VueVisible);

const router = new VueRouter({
	mode: 'history',
	base: '/app',
	scrollBehavior(to, from, savedPosition) {
		if (to.hash && to.hash !== '#') {
			return {
				selector: to.hash,
			};
		}
		if (to.name == 'Pages Overview' && savedPosition) {
			return savedPosition;
		}
		return { x: 0, y: 0 };
	},
});

router.onError((err) => {
	if (!AppStateModule.online) {
		DialogService.openErrorDialog({
			header: {
				title: window.App.router.$t('dialogHeaderOffline'),
			},
			body: {
				content: window.App.router.$t('dialogTextLoadError'),
			},
		});
	} else if (err?.message) {
		let dialogErrorText = window.App.router.$t('dialogTextError');
		dialogErrorText += `\n\nError: ${err.message}`;
		DialogService.openErrorDialog({
			body: {
				content: dialogErrorText,
			},
		});
	}
});

Vue.use(
	ScrollBehavior,
	{ router, delay: 100 },
);

@Component({
	router,
	components: {
		DialogView,
		WebPushSoftAskView,
		SupportView,
	},
})
export default class AppRouterView extends Vue.extend(Template) {
	protected get showSupport() {
		return (
			ConfigModule.partnerID === 2
			&& AppStateModule.showSupportButton
		);
	}

	protected get webPushAvailable() {
		return AppStateModule.webPushAvailable;
	}

	protected componentKey = 0;

	protected loading = true;

	protected created() {
		window.addEventListener(
			'online',
			this.setOnline,
		);
		window.addEventListener(
			'offline',
			this.setOffline,
		);

		this.$router.beforeEach((to, from, next) => {
			this.loading = true;
			next();
		});
		this.$router.afterEach(() => {
			this.loading = false;
		});
	}

	@Public()
	public back() {
		this.$router.go(-1);
	}

	@Public()
	public navigate(
		route: string,
		routeOptions: RouteOptions,
	) {
		routeOptions = _.extend(
			{
				replace: false,
			},
			routeOptions,
		);

		if (routeOptions.replace) {
			this.$router.replace(
				{ path: route },
				() => {
					if (routeOptions.onComplete) {
						routeOptions.onComplete();
					}
				},
			);
		} else {
			this.$router.push(
				{ path: route },
				() => {
					if (routeOptions.onComplete) {
						routeOptions.onComplete();
					}
				},
			);
		}
	}

	@Public()
	public openProduct(
		projectId: number | 'latest',
		replace: boolean,
		action?: 'extendProjectLifetime' | 'removeProject',
	) {
		// This route is only available to logged in users
		if (!UserModule.id) {
			EventBus.$once(
				'auth:login',
				(success: boolean) => {
					if (success) {
						this.openProduct(
							projectId,
							replace,
						);
					} else {
						navigate.toStart();
					}
				},
			);

			auth.showLogin({
				hasclose: false,
			});
		} else if (projectId == 'latest') {
			ProductsModule
				.fetch({
					requestOptions: {
						params: {
							limit: 1,
							orderby: 'id DESC',
						},
					},
				})
				.then(() => {
					if (ProductsModule.models.length) {
						const productModel = ProductsModule.models[0];
						window.App.router.openProduct(
							productModel.id,
							true,
						);
					} else {
						navigate.toStart();
					}
				})
				.catch((err) => {
					this.$openErrorDialog({
						body: {
							content: err.message,
						},
					});
					navigate.toStart();
				});
		} else {
			// In case the user was deeplinked with custom attributes, we need to add these to the project data
			const customAttributes = this.$route.query.hasOwnProperty(URL_PARAMETERS.customAttributes)
				? JSON.parse(this.$route.query[URL_PARAMETERS.customAttributes] as string)
				: undefined;

			ProductState
				.setup(
					projectId,
					{
						customAttributes,
						extendProjectLifetime: action && action === 'extendProjectLifetime',
					},
				)
				// Navigate into the project
				.then(() => {
					navigate.openProduct(
						projectId,
						{
							trigger: true,
							replace,
						},
					);

					const productModel = ProductStateModule.getProduct;
					if (productModel) {
						analytics.trackOpenProject(productModel);
					}

					if (
						action
						&& action === 'extendProjectLifetime'
					) {
						const closeAlert = this.$openAlertDialog({
							header: {
								title: this.$t('dialogs.projectLifetimeExtended.title'),
							},
							body: {
								content: this.$t('dialogs.projectLifetimeExtended.message'),
							},
							footer: {
								buttons: [
									{
										id: 'accept',
										text: this.$t('dialogs.projectLifetimeExtended.buttons.accept'),
										click: () => {
											closeAlert();
										},
									},
								],
							},
						});
					} else if (
						action
						&& action === 'removeProject'
					) {
						// Show confirmation dialog
						const closeConfirm = this.$openConfirmDialog({
							header: {
								title: this.$t('dialogHeaderRemoveProduct'),
							},
							body: {
								content: this.$t('dialogTextRemoveProduct'),
							},
							footer: {
								buttons: [
									{
										id: 'cancel',
										text: this.$t('dialogButtonCancel'),
										click: () => {
											closeConfirm();
										},
									},
									{
										id: 'accept',
										text: this.$t('dialogButtonRemoveProductOk'),
										click: () => {
											deleteProduct(projectId);

											navigate.toStart();
											closeConfirm();
										},
									},
								],
							},
						});
					}
				})
				.catch((err) => {
					let dialogErrorText = window.App.router.$t('dialogTextError');
					if (err.message) {
						dialogErrorText += `\n\nError: ${err.message}`;
					}

					this.$openErrorDialog({
						body: {
							content: dialogErrorText,
						},
					});

					navigate.toStart();
				});
		}
	}

	@Public()
	public reload() {
		// We might be in loading state
		this.loading = false;

		// We have all components rerendered by changing the key of the master element
		// This forces a rerender of all subcomponents (and translations)
		this.componentKey += 1;
	}

	@Public()
	public setLoading(loading: boolean) {
		this.loading = loading;
	}

	@Public()
	public removeURLParameter(parameter: string) {
		const query = { ...this.$route.query };
		delete query[parameter];

		return this.$router.replace({ query });
	}

	private setOnline() {
		AppStateModule.setOnline();
	}

	private setOffline() {
		AppStateModule.setOffline();
	}
}
