import {
	CartItemsModule,
	ProductsModule,
	ProductStateModule,
} from '../../store/index';
import ProductState from '../../classes/productstate';

const deleteProduct = (productId: number) => {
	// reset product under construction
	if (ProductStateModule.productId == productId) {
		ProductState.reset();
	}

	// remove product from shopping cart
	const toRemove = CartItemsModule.where({ productid: productId });

	// Remove cartitem with this product
	toRemove.forEach(
		(cartItemModel) => {
			CartItemsModule.destroyModel({ id: cartItemModel.id });
		},
	);

	const productModel = ProductsModule.getById(productId);
	if (productModel) {
		// Remove from local storage (if exists)
		ProductState
			.removeFromStorage(productModel.read_token)
			.catch(() => {
				// Swallow error: no action required
			});
	} else if (typeof window.glBugsnagClient !== 'undefined') {
		const e = new Error('Could not find product to remove');
		window.glBugsnagClient.notify(
			e,
			(event) => { event.severity = 'warning'; },
		);
	}

	ProductsModule.destroyModel(productId);
};

export default deleteProduct;
